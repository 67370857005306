import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  link: '',
  name: '',
  symbol: '',
  price: '',
  growth: ''
};

export const cryptoDataSlice = createSlice({
  name: 'cryptoData',
  initialState,
  reducers: {
    setCryptoData: (state, action) => {
      state.link = action.payload.link;
      state.name = action.payload.name;
      state.symbol = action.payload.symbol;
      state.price = action.payload.price;
      state.growth = action.payload.growth;
      state.id = action.payload.id
    }
  }
});

export const { setCryptoData } = cryptoDataSlice.actions;

export default cryptoDataSlice.reducer;