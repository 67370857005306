import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from './features/coinsSlice';
import { selectTimePeriod, selectSorting } from './features/timePeriodSlice';
import { useEffect } from 'react';
import Bar from './components/Bar/Bar';
import Home from './pages/Home/Home';
import List from './pages/List/List';
import Loader from './components/Loader/Loader';
import Watchlist from './pages/Watchlist/Watchlist'
import Portfolio from './pages/Portfolio/Portfolio';
import PortfolioInfo from './pages/Portfolio/PortfolioInfo/PortfolioInfo';
import userId from './api/userId';

const tg = window.Telegram.WebApp;

function App() {
    const dispatch = useDispatch();
    
    const { coins, loading } = useSelector((state) => state.data);
    const timePeriod = useSelector(selectTimePeriod);
    const sorting = useSelector(selectSorting);

    useEffect(() => {
        tg.ready();
        tg.expand();
        tg.setHeaderColor("#15151F")
        dispatch(fetchData({ userId }));
        }, [dispatch]);


    const timePeriods = {
            '1h': 'hour',
            '24h': 'day',
            '7d': 'week',
            '30d': 'month',
            "1q": "quarter",
            '1y': 'year'
        };

    const getSliceRange = (sorting) => {
        switch (sorting) {
            case 'top50':
                return [0, 50];
            case 'top100':
                return [0, 100];
            case '100-200':
                return [100, 200];
            case '200-300':
                return [200, 300];
            case '300-400':
                return [300, 400];
            case '400-500':
                return [400, 500];
            case '500-600':
                return [500, 600];
            case '600-700':
                return [600, 700];
            case '700-800':
                return [700, 800];
            case '800-900':
                return [800, 900];
            case '900-1000':
                return [900, 1000];
            case '1000-1100':
                return [1000, 1100];
            case '1100-1200':
                return [1100, 1200];
            case '1200-1300':
                return [1200, 1300];
            case '1300-1400':
                return [1300, 1400];
            case '1400-1500':
                return [1400, 1500];
            case '1500-1600':
                return [1500, 1600];
            case '1600-1700':
                return [1600, 1700];
            case '1700-1800':
                return [1700, 1800];
            case '1800-1900':
                return [1800, 1900];
            case '1900-2000':
                return [1900, 2000];  
            default:
                return [0, 50]; // По умолчанию возвращаем первые 50
        }
    };

    const [start, end] = getSliceRange(sorting);

    
    const coinData = coins && coins.length > 0 
    ? coins.slice(start, end).map(coin => ({
        id: coin._id,
        name: coin.name,
        symbol: coin.code,
        price: coin.rate,
        growth: coin.percentageDelta[timePeriods[timePeriod]],
        link: coin.png64
    })) 
    : [];

    return (
    <BrowserRouter>
        <Bar/>
        {loading ? (
        <Loader />
        ) : (
            <Routes>
            <Route path="/" element={<Home data={coinData} />} />
            <Route path="/list" element={<List data={coinData} />} />
            <Route path="/watchlist" element={<Watchlist/>} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/portfolioinfo" element={<PortfolioInfo/>} />
            </Routes>
        )}
    </BrowserRouter>
    );
}

export default App;


// import { fetchWatchlists } from './features/watchlistSlice';

// const handleUpdate = async () => {
//     try {
//         const response = await axios.post('/api/active-users/updateActivityDate', { telegramId });
//         setMessage(response.data.message);
//         setError(null);
//     } catch (err) {
//         setError(err.message);
//         setMessage('');
//     }
// };

