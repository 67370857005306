import axios from 'axios';

// // Local
// const instance = axios.create({
//     baseURL: 'http://localhost:5000'
// });

// Server
const instance = axios.create({
    baseURL: 'https://gameapi.space:5000'
});

export default instance;