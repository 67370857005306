import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    setWatchlistIndex: (state, action) => action.payload,
  },
});

export const { setWatchlistIndex } = watchlistSlice.actions;

export const selectWatchlistIndex = (state) => state.watchlist;

export default watchlistSlice.reducer;