import React from 'react';
import { useDispatch } from 'react-redux';
import { setTimePeriod, setSorting, setNumber } from '../../features/timePeriodSlice';
import "./PopupMenu.css";
import { useEffect } from 'react';
import MenuTime from './MenuTime/MenuTime';
import MenuPages from './MenuPages/MenuPages';
import MenuChart from './MenuChart/MenuChart';
import MenuFollow from './MenuFollow/MenuFollow';
import MenuWatchlists from './MenuWatchlists/MenuWatchlists';
import MenuManageWatchlists from './MenuManageWatchlists/MenuManageWatchlists';
import MenuPortfolio from './MenuPortfolio/MenuPortfolio'
import MenuManagePortfolio from './MenuManagePortfolio/MenuManagePortfolio'
import MenuBuy from './MenuBuy/MenuBuy';


const PopupMenu = ({ set, show, content }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const menu = document.querySelector('.popup-menu');
    const backdrop = document.querySelector('.backdrop')
    if (show) {
      menu.classList.add('active');
      backdrop.classList.add('show')
    } else {
      menu.classList.remove('active');
      backdrop.classList.remove('show')
    }
  }, [show]);

  const handleMenuClick = (option) => {
      if (content === 'menuTime') {
        dispatch(setTimePeriod(option));
        // setTimeout(() => set(false), 300);
      } else if (content === 'menuPages') {
        dispatch(setSorting(option));
        setTimeout(() => set(false), 300);
      }
      
  };

  const handleMenuClickRange = (option) => {
    setTimeout(() => {
      if (content === 'menuPages') {
        dispatch(setNumber(option));
      }
      set(false);
    }, 100);
  };


  const renderMenuContent = () => {
    switch (content) {
      case 'menuTime':
        return <MenuTime handleMenuClick={handleMenuClick} />;
      case 'menuPages':
        return <MenuPages handleMenuClickRange={handleMenuClickRange} />;
      case 'menuChart':
        return <MenuChart/>;
      case 'menuFollow':
        return <MenuFollow/>
      case 'menuWatchlists':
        return <MenuWatchlists/>
      case 'menuManageWatchlists':
        return <MenuManageWatchlists/>
      case 'menuManagePortfolio':
        return <MenuManagePortfolio/>
      case 'menuPortfolio':
        return <MenuPortfolio/>
      case 'menuBuy':
        return <MenuBuy/>
      default:
        return null;
    }
  };

  return (
    <div className="popup-menu">
      <div className="popup-content">
        <div className="title_menu">
          <div className='menu_name'>
              {content === 'menuTime' ? <Timerange/> : content === 'menuPages' ? <Filter/> : content === 'menuWatchlists' ? <Filter/> : content === 'menuPortfolio' ? <Filter/> : ''}
            <p>{content === 'menuTime' ? 'Time range' : content === 'menuPages' ? 'Range selection' : content === 'menuFollow' ? 'Add to Watchlist' : content === 'menuManageWatchlists' ? 'Manage' : content === 'menuManagePortfolio' ? 'Manage' : content === 'menuWatchlists' ? 'Pages' : content === 'menuPortfolio' ? 'Pages' : content === 'menuBuy' ? 'New transaction' : 'Price Graph'}</p>
          </div>
          
          <svg onClick={() => set(!show)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <CloseIcon />
          </svg>
        </div>
        {renderMenuContent()}
      </div>
    </div>
  );
};

const CloseIcon = () => (
  <path fillRule="evenodd" clipRule="evenodd" d="M13.9484 15.6479C14.4177 16.1173 15.1786 16.1173 15.648 15.6479C16.1173 15.1786 16.1173 14.4177 15.648 13.9484L9.69956 7.99999L15.6479 2.05161C16.1173 1.58229 16.1173 0.821377 15.6479 0.352059C15.1786 -0.117259 14.4177 -0.117259 13.9484 0.352059L8.00001 6.30044L2.05155 0.351989C1.58223 -0.11733 0.821321 -0.117329 0.352003 0.351989C-0.117315 0.821307 -0.117316 1.58222 0.352003 2.05154L6.30046 7.99999L0.351989 13.9485C-0.11733 14.4178 -0.117329 15.1787 0.351989 15.648C0.821307 16.1173 1.58222 16.1173 2.05154 15.648L8.00001 9.69954L13.9484 15.6479Z" fill="white" opacity="0.6"/>
);

const Timerange = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='svg_menu'>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V12C11.25 12.3228 11.4566 12.6094 11.7628 12.7115L14.7628 13.7115C15.1558 13.8425 15.5805 13.6301 15.7115 13.2372C15.8425 12.8442 15.6301 12.4195 15.2372 12.2885L12.75 11.4594V7Z" fill="#539DF3"/>
  </svg>
);

const Filter = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='svg_menu'>
    <path d="M18.8188 2H5.18117C3.442 2 2.40466 3.91555 3.36937 5.34564L9.09107 12.8274C9.56799 13.5344 9.82249 14.3651 9.82249 15.2148V20.9219C9.82249 21.8805 10.9952 22.3605 11.6811 21.6827L13.8586 19.5307C14.0628 19.329 14.1775 19.0553 14.1775 18.7699V15.2148C14.1775 14.3651 14.432 13.5344 14.9089 12.8274L20.6306 5.34564C21.5953 3.91555 20.558 2 18.8188 2Z" fill="#539DF3"/>
  </svg>
)

export default PopupMenu;