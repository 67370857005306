import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './CryptoPopup.css';
import { selectTimePeriod } from '../../features/timePeriodSlice';

const CryptoPopup = () => {
    // Получаем данные из Redux
    const cryptoData = useSelector(state => state.cryptoData);
    const timePeriod = useSelector(selectTimePeriod);
    const location = useLocation();
    const currentPath = location.pathname;

    const formattedGrowth = parseFloat(cryptoData.growth).toFixed(2);
    const growthStyle = {
        color: formattedGrowth.startsWith('-') ? '#E83636' : '#68B820',
        fontSize: '12px'
    };

    function formatPrice(price) {
      // Преобразование строки в число
      const num = parseFloat(price);
    
      // Проверка на валидное число
      if (isNaN(num)) {
          return 'Invalid number';
      }
    
      // Форматирование больших чисел с двумя знаками после запятой
      if (num >= 1) {
          return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
    
      // Преобразование числа обратно в строку для манипуляций
      const priceString = num.toString();
    
      // Форматирование чисел меньше 1 но больше или равных 0.0001
      if (num < 1 && num >= 0.0001) {
          const decimalPart = priceString.split('.')[1] || '';
          const nonZeroIndex = decimalPart.search(/[^0]/);
          const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
          return `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
      }
    
      // Форматирование очень маленьких чисел с 4 значащими цифрами после последних нулей
      if (num < 0.0001) {
          const decimalPart = priceString.split('.')[1] || '';
          const nonZeroIndex = decimalPart.search(/[^0]/);
          const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
          return `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
      }
    
      // Возвращаем число как есть для 1
      return num.toString();
  }

    return (
        <div className='container_cryptoPopup'>
            <div className='crypto_infoPopup'>
                <img src={cryptoData.link} alt={cryptoData.name} style={{ width: "35px", height: "35px", marginRight: "10px" }} onError={(e) => { e.target.src = '/erricon.png'; }} />
                <div>
                    <p style={{fontSize: "16px", opacity: "1"}}>{cryptoData?.symbol?.replace(/_/g, "")}</p>
                </div>
            </div>
            <div className='price_cryptoPopup'>
                <div>
                    <p style={{fontSize: '14px'}}>${formatPrice(cryptoData?.price?.toString())}</p>
                </div>
                <div>
                    {currentPath === '/portfolio' 
                    ? <p style={growthStyle}>{formattedGrowth}%</p>
                    : <p style={growthStyle}>{formattedGrowth}% <span>({timePeriod})</span></p>
                    }
                    
                </div>
            </div>
        </div>
    );
}

export default CryptoPopup;