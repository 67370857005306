import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setPortfolioIndex: (state, action) => action.payload,
  },
});

export const { setPortfolioIndex } = portfolioSlice.actions;

export const selectPortfolioIndex = (state) => state.portfolio;

export default portfolioSlice.reducer;