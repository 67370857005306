import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setMenu, closeMenu } from '../../features/menuSlice'
import "./Bar.css"
import { CSSTransition } from 'react-transition-group';
import PopupMenu from '../PopupMenu/PopupMenu';

const Bar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const dispatch = useDispatch();
  const { showMenu, menuContent } = useSelector(state => state.menu);

  const handleToggleMenu = (content, show) => {
    dispatch(setMenu({ content, show }));
  };

  const handleClick = () => {
    const currentPath = location.pathname;
    if (currentPath === '/' || currentPath === '/list') {
        handleToggleMenu('menuPages', true);
    } else if (currentPath === '/watchlist') {
        handleToggleMenu('menuWatchlists', true);
    } else if (currentPath === '/portfolio') {
        handleToggleMenu('menuPortfolio', true);
    } else if (currentPath === '/portfolioinfo') {
      handleToggleMenu('menuPortfolio', true);
  }
};

  return (
    <>
      <CSSTransition
        in={showMenu}
        timeout={300}
        classNames="popup-menu"
        unmountOnExit
      >
        <>
          <div className="backdrop" onClick={() => dispatch(closeMenu())} />
          <PopupMenu set={() => dispatch(closeMenu())} show={showMenu} content={menuContent} />
        </>
      </CSSTransition>
      <div className='container_bar'>
      {currentPath === '/portfolio' ? 
        <Link to='/portfolioinfo' className='btn_bar'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 14.3734 0.703788 16.6934 2.02236 18.6668C3.34094 20.6402 5.21508 22.1783 7.4078 23.0865C9.60051 23.9948 12.0133 24.2324 14.3411 23.7694C16.6689 23.3064 18.807 22.1635 20.4853 20.4853C22.1635 18.807 23.3064 16.6689 23.7694 14.3411C24.2324 12.0133 23.9948 9.60051 23.0866 7.4078C22.1783 5.21508 20.6402 3.34094 18.6668 2.02236C16.6935 0.703786 14.3734 -1.12813e-06 12 -1.12813e-06C8.81835 0.00309838 5.76791 1.26838 3.51814 3.51814C1.26838 5.7679 0.00309951 8.81835 0 12ZM15.079 16.2732C15.3274 16.2732 15.5656 16.3718 15.7413 16.5475C15.9169 16.7231 16.0156 16.9614 16.0156 17.2098C16.0156 17.4581 15.9169 17.6964 15.7413 17.872C15.5656 18.0477 15.3274 18.1463 15.079 18.1463H8.93268C8.68428 18.1463 8.44606 18.0477 8.27042 17.872C8.09477 17.6964 7.9961 17.4581 7.9961 17.2098C7.9961 16.9614 8.09477 16.7231 8.27042 16.5475C8.44606 16.3718 8.68428 16.2732 8.93268 16.2732H15.079ZM17.081 11.0049C17.3294 11.0049 17.5676 11.1035 17.7432 11.2792C17.9189 11.4548 18.0176 11.6931 18.0176 11.9415C18.0176 12.1899 17.9189 12.4281 17.7432 12.6037C17.5676 12.7794 17.3294 12.878 17.081 12.878L6.91902 12.878C6.67063 12.878 6.4324 12.7794 6.25676 12.6037C6.08111 12.4281 5.98244 12.1899 5.98244 11.9415C5.98244 11.6931 6.08111 11.4548 6.25676 11.2792C6.4324 11.1035 6.67063 11.0049 6.91902 11.0049L17.081 11.0049ZM15.079 5.73658C15.3274 5.73658 15.5656 5.83526 15.7413 6.0109C15.9169 6.18655 16.0156 6.42477 16.0156 6.67317C16.0156 6.92157 15.9169 7.15979 15.7413 7.33543C15.5656 7.51108 15.3274 7.60975 15.079 7.60975H8.93268C8.68428 7.60975 8.44606 7.51108 8.27042 7.33543C8.09477 7.15979 7.9961 6.92157 7.9961 6.67317C7.9961 6.42477 8.09477 6.18655 8.27042 6.0109C8.44606 5.83526 8.68428 5.73658 8.93268 5.73658H15.079Z" fill="white" opacity={currentPath === '/portfolioinfo' ? "1" : "0.6"}/>
          </svg>
        </Link>      
      : 
      currentPath === '/portfolioinfo' ?
      <Link to='/portfolioinfo' className='btn_bar'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 14.3734 0.703788 16.6934 2.02236 18.6668C3.34094 20.6402 5.21508 22.1783 7.4078 23.0865C9.60051 23.9948 12.0133 24.2324 14.3411 23.7694C16.6689 23.3064 18.807 22.1635 20.4853 20.4853C22.1635 18.807 23.3064 16.6689 23.7694 14.3411C24.2324 12.0133 23.9948 9.60051 23.0866 7.4078C22.1783 5.21508 20.6402 3.34094 18.6668 2.02236C16.6935 0.703786 14.3734 -1.12813e-06 12 -1.12813e-06C8.81835 0.00309838 5.76791 1.26838 3.51814 3.51814C1.26838 5.7679 0.00309951 8.81835 0 12ZM15.079 16.2732C15.3274 16.2732 15.5656 16.3718 15.7413 16.5475C15.9169 16.7231 16.0156 16.9614 16.0156 17.2098C16.0156 17.4581 15.9169 17.6964 15.7413 17.872C15.5656 18.0477 15.3274 18.1463 15.079 18.1463H8.93268C8.68428 18.1463 8.44606 18.0477 8.27042 17.872C8.09477 17.6964 7.9961 17.4581 7.9961 17.2098C7.9961 16.9614 8.09477 16.7231 8.27042 16.5475C8.44606 16.3718 8.68428 16.2732 8.93268 16.2732H15.079ZM17.081 11.0049C17.3294 11.0049 17.5676 11.1035 17.7432 11.2792C17.9189 11.4548 18.0176 11.6931 18.0176 11.9415C18.0176 12.1899 17.9189 12.4281 17.7432 12.6037C17.5676 12.7794 17.3294 12.878 17.081 12.878L6.91902 12.878C6.67063 12.878 6.4324 12.7794 6.25676 12.6037C6.08111 12.4281 5.98244 12.1899 5.98244 11.9415C5.98244 11.6931 6.08111 11.4548 6.25676 11.2792C6.4324 11.1035 6.67063 11.0049 6.91902 11.0049L17.081 11.0049ZM15.079 5.73658C15.3274 5.73658 15.5656 5.83526 15.7413 6.0109C15.9169 6.18655 16.0156 6.42477 16.0156 6.67317C16.0156 6.92157 15.9169 7.15979 15.7413 7.33543C15.5656 7.51108 15.3274 7.60975 15.079 7.60975H8.93268C8.68428 7.60975 8.44606 7.51108 8.27042 7.33543C8.09477 7.15979 7.9961 6.92157 7.9961 6.67317C7.9961 6.42477 8.09477 6.18655 8.27042 6.0109C8.44606 5.83526 8.68428 5.73658 8.93268 5.73658H15.079Z" fill="white" opacity={currentPath === '/portfolioinfo' ? "1" : "0.6"}/>
          </svg>
      </Link>  
      :
        <div className='btn_bar' onClick={() => handleToggleMenu('menuTime', true)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.9 6C12.9 5.50294 12.4971 5.1 12 5.1C11.5029 5.1 11.1 5.50294 11.1 6V12C11.1 12.3874 11.3479 12.7313 11.7154 12.8538L15.3154 14.0538C15.7869 14.211 16.2966 13.9562 16.4538 13.4846C16.611 13.0131 16.3562 12.5034 15.8846 12.3462L12.9 11.3513V6Z" fill="#539DF3"/>
          </svg>
        </div>
      }
      

      <div className='btn_bar' onClick={handleClick}>
        <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.4553 0H2.5447C0.515672 0 -0.694566 2.29866 0.430935 4.01476L7.10625 12.9929C7.66266 13.8413 7.95957 14.8381 7.95957 15.8578V22.7062C7.95957 23.8565 9.32777 24.4326 10.128 23.6192L12.6684 21.0369C12.9066 20.7948 13.0404 20.4663 13.0404 20.1239V15.8578C13.0404 14.8381 13.3373 13.8413 13.8938 12.9929L20.5691 4.01476C21.6946 2.29866 20.4843 0 18.4553 0Z" fill="#539DF3"/>
        </svg>
        {/* <p>Top 50</p> */}
      </div>

      <Link to="/" className='btn_bar'>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M19.3194 11.9358C22.2155 11.5097 24.4386 9.01447 24.4386 5.99999C24.4386 2.68629 21.7523 0 18.4386 0C15.4241 0 12.9289 2.22306 12.5028 5.11919C12.4605 5.40666 12.4386 5.70077 12.4386 5.99999C12.4386 9.31369 15.1249 12 18.4386 12C18.7378 12 19.0319 11.9781 19.3194 11.9358ZM10.0394 4.80001C10.2936 4.80001 10.5455 4.80989 10.7947 4.82929C10.7888 4.86558 10.7832 4.90196 10.7779 4.93843C10.7229 5.31214 10.6944 5.69448 10.6944 6.08346C10.6944 10.3913 14.1866 13.8834 18.4944 13.8834C18.877 13.8834 19.2532 13.8559 19.6211 13.8027C19.6333 14.0002 19.6394 14.1994 19.6394 14.4C19.6394 19.7019 15.3414 24 10.0394 24C4.73751 24 0.439453 19.7019 0.439453 14.4C0.439453 9.09807 4.73751 4.80001 10.0394 4.80001Z" fill="white" opacity={currentPath === '/' ? "1" : "0.6"}/>
        </svg>
        {/* <p>Home</p> */}
      </Link>

      <Link to="/list" className='btn_bar'>
        <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M16.0411 0.86747C16.0411 0.388379 15.6493 0 15.1661 0C14.6828 0 14.2911 0.388379 14.2911 0.86747V2.60241H6.70776V0.86747C6.70776 0.388379 6.31601 0 5.83276 0C5.34951 0 4.95776 0.388379 4.95776 0.86747V2.60241H4.66667C2.08934 2.60241 0 4.67377 0 7.22892V19.3735C0 21.9286 2.08934 24 4.66667 24H16.3333C18.9107 24 21 21.9286 21 19.3735V7.22892C21 4.67377 18.9107 2.60241 16.3333 2.60241H16.0411V0.86747ZM5.83333 8.09639C5.35008 8.09639 4.95833 8.48476 4.95833 8.96386C4.95833 9.44295 5.35008 9.83132 5.83333 9.83132H10.5C10.9832 9.83132 11.375 9.44295 11.375 8.96386C11.375 8.48476 10.9832 8.09639 10.5 8.09639H5.83333ZM4.95833 13.5904C4.95833 13.1113 5.35008 12.7229 5.83333 12.7229H15.1667C15.6499 12.7229 16.0417 13.1113 16.0417 13.5904C16.0417 14.0695 15.6499 14.4578 15.1667 14.4578H5.83333C5.35008 14.4578 4.95833 14.0695 4.95833 13.5904ZM5.83333 17.3494C5.35008 17.3494 4.95833 17.7378 4.95833 18.2169C4.95833 18.696 5.35008 19.0843 5.83333 19.0843H15.1667C15.6499 19.0843 16.0417 18.696 16.0417 18.2169C16.0417 17.7378 15.6499 17.3494 15.1667 17.3494H5.83333Z" fill="white" opacity={currentPath === '/list' ? "1" : "0.6"}/>
        </svg>
      </Link>

      <Link to="/watchlist" className='btn_bar'>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.63937 1.52569C10.605 -0.508562 13.395 -0.508566 14.3606 1.52569L16.0295 5.04141C16.4129 5.84922 17.1541 6.40912 18.0116 6.53866L21.7432 7.10243C23.9024 7.42864 24.7646 10.1875 23.2022 11.7709L20.5019 14.5075C19.8815 15.1363 19.5983 16.0422 19.7448 16.9301L20.3823 20.7943C20.7511 23.0301 18.4939 24.7352 16.5627 23.6795L13.225 21.8551C12.4581 21.4359 11.5419 21.4359 10.775 21.8551L7.43732 23.6795C5.50607 24.7352 3.24891 23.0301 3.61774 20.7943L4.25519 16.9301C4.40165 16.0422 4.11853 15.1363 3.4981 14.5075L0.79785 11.7709C-0.764561 10.1875 0.0975938 7.42864 2.25679 7.10243L5.98845 6.53866C6.84587 6.40912 7.58708 5.84922 7.97053 5.04141L9.63937 1.52569Z" fill="white" opacity={currentPath === '/watchlist' ? "1" : "0.6"}/>
        </svg>
        {/* <p>Watchlist</p> */}
      </Link>

      <Link to="/portfolio" className='btn_bar'>
        <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.5 0C8.13152 0 6.21149 1.9419 6.21149 4.33735V5.49467C4.04446 5.53241 2.19591 7.10612 1.79514 9.26787L0.0797406 18.5209C-0.448101 21.3681 1.71159 24 4.5758 24H16.4242C19.2884 24 21.4481 21.3681 20.9203 18.5209L19.2049 9.26787C18.8041 7.10612 16.9555 5.53241 14.7885 5.49467V4.33735C14.7885 1.9419 12.8685 0 10.5 0ZM13.0731 5.49398V4.33735C13.0731 2.90008 11.9211 1.73494 10.5 1.73494C9.07891 1.73494 7.92689 2.90008 7.92689 4.33735V5.49398H13.0731Z" fill="white" opacity={currentPath === '/portfolio' ? "1" : "0.6"}/>
        </svg>
        {/* <p>Portfolio</p> */}
      </Link>

    </div>
    </>
  )
}

export default Bar