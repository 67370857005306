import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { makeSelectPortfolios, makeSelectCryptoData } from '../../../../features/dataSelector';
import { sellCrypto } from '../../../../features/coinsSlice';

const telegramId = "2038669608";

const Sell = () => {
    const dispatch = useDispatch();
    const portfolios = useSelector(makeSelectPortfolios);
    const cryptoData = useSelector(makeSelectCryptoData);

    const [valueAmount, setValueAmount] = useState('');
    const [valuePrice, setValuePrice] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOverBalance, setIsOverBalance] = useState(false);

    useEffect(() => {
        if (cryptoData?.price) {
            setValuePrice(handleReplacePrice(cryptoData.priceFormatted));
        }
    }, [cryptoData]);

    useEffect(() => {
        if (portfolios.length > 0) {
            const optionsWithCoin = getOptionsWithCoin(portfolios, cryptoData.symbol);
            if (optionsWithCoin.length > 0) {
                setSelectedOption(optionsWithCoin[0]);
            } else {
                setSelectedOption(null);
            }
        }
    }, [portfolios, cryptoData]);

    const handleChangeAmount = (event) => {
        const amount = event.target.value;
        setValueAmount(amount);

        if (selectedOption) {
            const balance = getAmountBySymbol(portfolios.find(p => p._id === selectedOption.value), cryptoData.symbol);
            if (parseFloat(amount) > balance) {
                setIsOverBalance(true);
            } else {
                setIsOverBalance(false);
            }
        }
    };

    const handleChangePrice = (event) => {
        setValuePrice(event.target.value);
    };

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const handleReplacePrice = (num) => {
        return num.replace(/,/g, '');
    };

    const handleAddTransaction = () => {
        if (selectedOption && valueAmount > 0 && valuePrice > 0) {
            const data = {
                telegramId: telegramId,
                portfolioId: selectedOption.value,
                symbol: cryptoData.symbol,
                amount: valueAmount,
                price: valuePrice,
            };
            dispatch(sellCrypto(data));
            setValueAmount('');
            setValuePrice('');
        }
    };

    const handleMaxClick = () => {
        if (selectedOption) {
            const balance = getAmountBySymbol(portfolios.find(p => p._id === selectedOption.value), cryptoData.symbol);
            setValueAmount(balance);
            setIsOverBalance(false);
        }
    };

    const handleKeyPress = (event) => {
        const invalidChars = ['-', '+', 'e'];
        if (invalidChars.includes(event.key)) {
            event.preventDefault();
        }
    };

    const getOptionsWithCoin = (portfolios, symbol) => {
        return portfolios
            .filter(portfolio => portfolio.coins.some(coin => coin.symbol === symbol))
            .map(portfolio => ({
                value: portfolio._id,
                label: portfolio.name,
            }));
    };

    const options = getOptionsWithCoin(portfolios, cryptoData.symbol);

    const truncateWithEllipsis = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        }
        return str;
    };

    function formatPrice(price) {
        const num = parseFloat(price);

        if (isNaN(num)) {
            return 'Invalid number';
        }

        if (num >= 1) {
            return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }

        const priceString = num.toString();

        if (num < 1 && num >= 0.0001) {
            const decimalPart = priceString.split('.')[1] || '';
            const nonZeroIndex = decimalPart.search(/[^0]/);
            if (nonZeroIndex === -1) return '0.0000';
            const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
            return `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
        }

        if (num < 0.0001) {
            const decimalPart = priceString.split('.')[1] || '';
            const nonZeroIndex = decimalPart.search(/[^0]/);
            if (nonZeroIndex === -1) return '0.0000';
            const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
            return `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
        }

        return num.toString();
    }

    const getAmountBySymbol = (portfolio, symbol) => {
        const coin = portfolio.coins.find(coin => coin.symbol === symbol);
        return coin ? coin.amount : 0;
    };

    const amountCoin = selectedOption ? getAmountBySymbol(portfolios.find(p => p._id === selectedOption.value), cryptoData.symbol) : 0;
    const isButtonDisabled = !selectedOption || valueAmount <= 0 || valuePrice <= 0 || amountCoin < valueAmount;

    return (
        <div>
            <div style={{ color: '#fff', backgroundColor: '#0F0F16', borderBottom: "solid 1px rgb(255,255,255,0.1)"}}>
                <p style={{ color: "rgb(255,255,255,0.6)", fontSize: "12px" }}>Portfolio</p>
                <div style={{ position: 'relative', top: '-4px' }}>
                    <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                        styles={{
                            control: (base, state) => ({
                                ...base,
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                color: '#fff',
                                padding: '0 0',
                                fontSize: '16px',
                                boxShadow: state.isFocused ? 'none' : base.boxShadow,
                                minHeight: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center'
                            }),
                            singleValue: (base) => ({
                                ...base,
                                color: '#fff',
                                paddingLeft: 0,
                                marginLeft: 0,
                            }),
                            menu: (base) => ({
                                ...base,
                                backgroundColor: '#15151F',
                                color: '#fff',
                            }),
                            valueContainer: (base) => ({
                                ...base,
                                paddingLeft: 0,
                                paddingRight: 0,
                                marginLeft: 0,
                            }),
                            input: (base) => ({
                                ...base,
                                margin: 0,
                                padding: 0,
                                caretColor: 'transparent',
                                color: '#fff',
                                width: 'auto',
                            }),
                            placeholder: (base) => ({
                                ...base,
                                margin: 0,
                                padding: 0,
                                color: 'rgba(255,255,255,0.6)',
                            }),
                            indicatorSeparator: () => ({
                                display: 'none',
                            }),
                            dropdownIndicator: (base) => ({
                                ...base,
                                padding: 0,
                                color: 'rgb(255,255,255,0.6)',
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#539DF3' : '#15151F',
                                color: state.isSelected ? '#fff' : '#fff',
                            }),
                        }}
                    />
                </div>
            </div>
            <div style={{ borderBottom: "solid 1px rgb(255,255,255,0.1)", padding: "10px 0px"}}>
                <p style={{fontSize: '12px', color: 'rgb(255,255,255,0.6)'}}>Trading pair</p>
                <p>{cryptoData?.symbol?.replace(/_/g, "")}/USD</p>
            </div>
            <div style={{ borderBottom: "solid 1px rgb(255,255,255,0.1)", padding: "10px 0px"}}>
                <p style={{fontSize: '12px', color: 'rgb(255,255,255,0.6)'}}>Coin balance</p>
                <p>{selectedOption ? getAmountBySymbol(portfolios.find(p => p._id === selectedOption.value), cryptoData.symbol) : 0}</p>
            </div>
            <div style={{ borderBottom: "solid 1px rgb(255,255,255,0.1)", padding: "10px 0px"}}>
                <p style={{fontSize: '12px', color: 'rgb(255,255,255,0.6)'}}>Selling price</p>
                <input type='number' value={valuePrice} placeholder='0' onChange={handleChangePrice} style={{all: "unset", width: "100%"}} onKeyPress={handleKeyPress} />
            </div>
            <div style={{ borderBottom: "solid 1px rgb(255,255,255,0.1)", padding: "10px 0px"}}>
                <p style={{fontSize: '12px', color: 'rgb(255,255,255,0.6)'}}>Amount coin</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="number"
                        value={valueAmount}
                        placeholder='0'
                        onChange={handleChangeAmount}
                        style={{
                            all: "unset",
                            width: "100%",
                            color: isOverBalance ? "#EB4335" : "inherit"
                        }}
                        onKeyPress={handleKeyPress}
                    />
                    <span
                        onClick={handleMaxClick}
                        style={{
                            all: "unset",
                            cursor: "pointer",
                            marginLeft: "10px",
                            color: "rgb(255,255,255,0.6)"
                        }}
                    >
                        Max
                    </span>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", color: "rgb(255,255,255,0.6)", padding: "10px 0px"}}>
                <p>-${truncateWithEllipsis(formatPrice(valuePrice * valueAmount), 15)} | -{truncateWithEllipsis(valueAmount, 15)} {cryptoData?.symbol?.replace(/_/g, "")}</p>
            </div>
            <div onClick={handleAddTransaction} style={{ 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center", 
                padding: "10px 0px", 
                backgroundColor: "#15151F", 
                borderRadius: "10px", 
                pointerEvents: isButtonDisabled ? 'none' : 'auto', 
                opacity: isButtonDisabled ? 0.5 : 1 
            }}>
                <Plus />
                <p>Add transaction</p>
            </div>
        </div>
    );
};

const Plus = () => (
    <svg className='svg_menu3' width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.6139 12.5152C22.2776 12.5152 22.8156 11.9772 22.8156 11.3135C22.8156 10.6498 22.2776 10.1117 21.6139 10.1117L13.2017 10.1117L13.2017 1.69961C13.2017 1.03591 12.6637 0.497875 12 0.497875C11.3363 0.497874 10.7983 1.03591 10.7983 1.69961L10.7983 10.1117L2.38605 10.1117C1.72235 10.1117 1.18431 10.6498 1.18431 11.3135C1.18431 11.9772 1.72235 12.5152 2.38605 12.5152L10.7983 12.5152L10.7983 20.9274C10.7983 21.5911 11.3363 22.1292 12 22.1292C12.6637 22.1292 13.2017 21.5911 13.2017 20.9274L13.2017 12.5152L21.6139 12.5152Z" fill="#539DF3"/>
    </svg>
);

export default Sell;