import React, { useState, useEffect } from 'react';
import Crypto from '../../components/Crypto/Crypto';
import { useSelector } from 'react-redux';
import { selectTimePeriod } from '../../features/timePeriodSlice';
import "./List.css";

const tg = window.Telegram.WebApp;

const List = ({ data }) => {

  useEffect(() => {
    tg.setHeaderColor("#0F0F16")
    }, []);

  const [searchTerm, setSearchTerm] = useState('');

  const timePeriods = {
    '1h': 'hour',
    '24h': 'day',
    '7d': 'week',
    '30d': 'month',
    '1q': 'quarter',
    '1y': 'year'
  };

  const { coins } = useSelector((state) => state.data);
  const timePeriod = useSelector(selectTimePeriod);
  const coinData = coins && coins.length > 0
    ? coins.map(coin => ({
        id: coin._id,
        name: coin.name,
        symbol: coin.code,
        price: coin.rate,
        growth: coin.percentageDelta[timePeriods[timePeriod]],
        link: coin.png64
    }))
    : [];

  const filteredData = searchTerm
    ? coinData.filter(coin => 
        coin.name.toLowerCase().startsWith(searchTerm.toLowerCase()) || 
        coin.symbol.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
    : data;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };


  return (
    <div className='container_list'>
      <div className="search-container">
        <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        <input
          type="text"
          placeholder="Search"
          className="search"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // Закрываем клавиатуру
              event.target.blur();
            }
          }}
        />
        {searchTerm && (
          <svg className="clear-icon" onClick={clearSearch} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        )}
      </div>
      {filteredData.map((coin, index) => (
        <Crypto
          key={index}
          id={coin.id}
          link={coin.link}
          name={coin.name}
          symbol={coin.symbol}
          price={coin.price.toString()}
          growth={coin.growth ? coin.growth.toString() : 'Not available'}
        />
      ))}
    </div>
  );
};

export default List;