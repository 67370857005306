import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axios';

/////////////////////////////////////////////////////////

// Thunk для получения всех портфелей пользователя
export const getAllPortfolios = createAsyncThunk(
    'portfolio/getAllPortfolios',
    async ({ telegramId }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/api/portfolio/${telegramId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Thunk для создания нового портфеля
export const createPortfolio = createAsyncThunk(
    'portfolio/createPortfolio',
    async ({ telegramId, name }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/portfolio/create', {
                telegramId,
                name
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Thunk для удаления портфеля
export const deletePortfolio = createAsyncThunk(
    'portfolio/deletePortfolio',
    async ({ telegramId, portfolioId }, { rejectWithValue }) => {
        try {
            await axios.delete('/api/portfolio/delete', {
                data: { telegramId, portfolioId }
            });
            return { portfolioId };
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Thunk для переименования портфеля
export const renamePortfolio = createAsyncThunk(
    'portfolio/renamePortfolio',
    async ({ telegramId, portfolioId, name }, { rejectWithValue }) => {
        try {
            const response = await axios.put('/api/portfolio/rename', {
                telegramId,
                portfolioId,
                name
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Thunk для покупки криптовалюты
export const buyCrypto = createAsyncThunk(
    'portfolio/buyCrypto',
    async ({ telegramId, portfolioId, symbol, amount, price }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/portfolio/buy', {
                telegramId,
                portfolioId,
                symbol,
                amount,
                price
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Thunk для продажи криптовалюты
export const sellCrypto = createAsyncThunk(
    'portfolio/sellCrypto',
    async ({ telegramId, portfolioId, symbol, amount }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/portfolio/sell', {
                telegramId,
                portfolioId,
                symbol,
                amount
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);
/////////////////////////////////////////////////////////

// Thunk для создания нового watchlist
export const createWatchlist = createAsyncThunk(
    'data/createWatchlist',
    async ({ userId }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/watchlist/create', {
                userId
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Thunk для удаления watchlist
export const deleteWatchlist = createAsyncThunk(
    'data/deleteWatchlist',
    async ({ userId, watchlistId }, { rejectWithValue }) => {
        try {
            await axios.post('/api/watchlist/delete', {
                userId,
                watchlistId
            });
            return { watchlistId };
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Thunk для добавления символа
export const addSymbol = createAsyncThunk(
    'data/addSymbol',
    async ({ userId, watchlistId, symbol }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/watchlist/add-symbol', {
                userId,
                watchlistId,
                symbol
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Thunk для удаления символа
export const removeSymbol = createAsyncThunk(
    'data/removeSymbol',
    async ({ userId, watchlistId, symbol }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/watchlist/remove-symbol', {
                userId,
                watchlistId,
                symbol
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Thunk для получения данных
export const fetchData = createAsyncThunk(
    'data/fetchData',
    async ({ userId }, { rejectWithValue }) => {
        try {
            const [coinsResponse, watchlistsResponse, portfoliosResponse] = await Promise.all([
                axios.get('/api/coins'),
                axios.get(`/api/watchlist/${userId}`),
                axios.get(`/api/portfolio/${userId}`)
            ]);
            return {
                coins: coinsResponse.data,
                watchlists: watchlistsResponse.data,
                portfolios: portfoliosResponse.data
            };
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

const cryptoEmojis = [
    '💰', '💸', '📈', '📉', '💹', '💲', '💳', '🪙', '📊', '🔐', 
    '🔒', '🛡️', '🛠️', '⚒️', '🔗', '🔎', '🔍', '🧠', '👨‍💻', 
    '👩‍💻', '🧑‍💻', '🏦', '🏧', '💱', '💵', '💶', '💷', '💴', 
    '📥', '📤', '🔄', '♻️', '🔔', '🔕', '🌐', '💻', '📱', 
    '⏳', '⌛', '🔧', '💡', '🚀', '🌟', '⭐', '🌍', '🌎', 
    '🌏', '⚙️', '🛒', '🏷️', '💬', '📝', '✉️', '📮', 
    '📨', '📧', '📪', '📫', '📬', '📭', '📦', '📡', 
    '💾', '📅', '📆', '🗓️', '🧭', '🧮', '🪛', '🧬', 
    '🔬', '🔭', '⚖️', '🔋', '🔌', '💻', '📟', '📠', 
    '🖥️', '🖨️', '🕹️', '🖱️', '🖲️', '💽', '💿', 
    '📀', '📺', '📻', '📡', '📞', '☎️', '📠', '📟', 
    '💼', '📂', '📁', '📋', '📜', '📒', '📔', '📕', 
    '📗', '📘', '📙', '📚', '📓', '📖', '📃', '📄', 
    '📑', '🔖', '✏️', '✒️', '🖋️', '🖊️', '🖌️', '🖍️', 
    '🗂️', '🗃️', '🗄️', '🗑️'
];

// Thunk для переименования watchlist
export const renameWatchlist = createAsyncThunk(
    'data/renameWatchlist',
    async ({ userId, watchlistId}, { rejectWithValue }) => {

        const randomEmoji = cryptoEmojis[Math.floor(Math.random() * cryptoEmojis.length)];
        const newName = `${randomEmoji} Watchlist`
        try {
            await axios.post('/api/watchlist/rename', {
                userId,
                watchlistId,
                newName
            });
            return { watchlistId, newName };
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

const dataSlice = createSlice({
    name: 'data',
    initialState: {
        coins: [],
        watchlists: [],
        portfolios: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.loading = false;
                state.coins = action.payload.coins;
                state.watchlists = action.payload.watchlists;
                state.portfolios = action.payload.portfolios;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addSymbol.fulfilled, (state, action) => {
                const { watchlistId, symbol } = action.meta.arg;
                const watchlist = state.watchlists.find(w => w._id === watchlistId);
                if (watchlist) {
                    watchlist.symbols.push(symbol);
                }
            })
            .addCase(removeSymbol.fulfilled, (state, action) => {
                const { watchlistId, symbol } = action.meta.arg;
                const watchlist = state.watchlists.find(w => w._id === watchlistId);
                if (watchlist) {
                    watchlist.symbols = watchlist.symbols.filter(s => s !== symbol);
                }
            })
            .addCase(createWatchlist.fulfilled, (state, action) => {
                state.watchlists.push(action.payload);
            })
            .addCase(deleteWatchlist.fulfilled, (state, action) => {
                state.watchlists = state.watchlists.filter(w => w._id !== action.payload.watchlistId);
            })
            .addCase(renameWatchlist.fulfilled, (state, action) => {
                const { watchlistId, newName } = action.payload;
                const watchlist = state.watchlists.find(w => w._id === watchlistId);
                if (watchlist) {
                    watchlist.name = newName;
                }
            })
            .addCase(createPortfolio.fulfilled, (state, action) => {
                state.portfolios.push(action.payload);
            })
            .addCase(deletePortfolio.fulfilled, (state, action) => {
                state.portfolios = state.portfolios.filter(p => p._id !== action.payload.portfolioId);
            })
            .addCase(renamePortfolio.fulfilled, (state, action) => {
                const portfolio = state.portfolios.find(p => p._id === action.payload._id);
                if (portfolio) {
                    portfolio.name = action.payload.name;
                }
            })
            .addCase(buyCrypto.fulfilled, (state, action) => {
                const portfolio = state.portfolios.find(p => p._id === action.payload._id);
                if (portfolio) {
                    const index = state.portfolios.indexOf(portfolio);
                    state.portfolios[index] = action.payload;
                }
            })
            .addCase(sellCrypto.fulfilled, (state, action) => {
                const portfolio = state.portfolios.find(p => p._id === action.payload._id);
                if (portfolio) {
                    const index = state.portfolios.indexOf(portfolio);
                    state.portfolios[index] = action.payload;
                }
            });
    }
});


export default dataSlice.reducer;