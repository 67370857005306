import { useSelector, useDispatch } from 'react-redux';
import { createPortfolio } from '../../../features/coinsSlice';
import { setMenu, closeMenu } from '../../../features/menuSlice'
import { selectPortfolioIndex, setPortfolioIndex } from '../../../features/portfolioSlise';
import "../MenuWatchlists/MenuWatchlists.css";
import telegramId from '../../../api/userId'

const cryptoEmojis = [
  '💰', '💸', '📈', '📉', '💹', '💲', '💳', '🪙', '📊', '🔐', 
  '🔒', '🛡️', '🛠️', '⚒️', '🔗', '🔎', '🔍', '🧠', '👨‍💻', 
  '👩‍💻', '💎', '🏦', '🏧', '💱', '💵', '💶', '💷', '💴', 
  '📥', '📤', '🔄', '♻️', '🔔', '🔕', '🌐', '💻', '📱', 
  '⏳', '⌛', '🔧', '💡', '🚀', '🌟', '⭐', '🌍', '🌎', 
  '🌏', '⚙️', '🛒', '🏷️', '💬', '📝', '✉️', '📮', 
  '📨', '📧', '📪', '📫', '📬', '📭', '📦', '📡', 
  '💾', '📅', '📆', '🗓️', '🧭', '🧮', '🪛', '🧬', 
  '🔬', '🔭', '⚖️', '🔋', '🔌', '💻', '📟', '📠', 
  '🖥️', '🖨️', '🕹️', '🖱️', '🖲️', '💽', '💿', 
  '📀', '📺', '📻', '📡', '📞', '☎️', '📠', '📟', 
  '💼', '📂', '📁', '📋', '📜', '📒', '📔', '📕', 
  '📗', '📘', '📙', '📚', '📓', '📖', '📃', '📄', 
  '📑', '🔖', '✏️', '✒️', '🖋️', '🖊️', '🖌️', '🖍️', 
  '🗂️', '🗃️', '🗄️', '🗑️'
];

const Menu4 = () => {
    const { portfolios } = useSelector((state) => state.data);
    const portfolioIndex = useSelector(selectPortfolioIndex);

    const dispatch = useDispatch();
    const handleToggleMenu = (content, show) => {
        dispatch(closeMenu())
        setTimeout(() => {
        dispatch(setMenu({ content, show }));
        }, 300);
    };

    const handleNewWatchlistClick = async () => {
      if (portfolios.length < 5) {
        const randomEmoji = cryptoEmojis[Math.floor(Math.random() * cryptoEmojis.length)];
        const newName = `${randomEmoji} Portfolio`
        dispatch(createPortfolio({ telegramId, "name" : newName }));
      }
      };

    const handleWatchlistClick = (index) => {
        dispatch(setPortfolioIndex(index));
        dispatch(closeMenu())
    };

    
    return (
        <>
        {portfolios.map((portfolio, index) => {
            return (
                <div 
                key={portfolio._id} 
                className='watchlists_con2'
                onClick={() => handleWatchlistClick(index)}
              >
                <div className={`p_w2 ${index === portfolioIndex ? 'active2' : ''}`}>
                    <p>{portfolio.name}</p>
                </div>
            </div>
            );
        })}
        <div className='btn_conm3'>
        <div className='btn_newm3 rightm3' onClick={handleNewWatchlistClick} style={{ pointerEvents: portfolios.length >= 5 ? 'none' : 'auto', opacity: portfolios.length >= 5 ? 0.5 : 1 }}>
          <p>New</p>
        </div>
        <div className='btn_newm3' onClick={() => handleToggleMenu('menuManagePortfolio', true)}>
          <p>Manage</p>
        </div>
      </div>
        </>
    )
    }

export default Menu4