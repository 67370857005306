import React from 'react';
import './Loader.css';

const Loader = () => {
    return (
      <div className="loader-container">
        <div className="loader">
          <div className="circle green"></div>
          <div className="circle red"></div>
        </div>
      </div>
    );
  };
  
  export default Loader;