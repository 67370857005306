import React, { useEffect } from 'react';
import PhysicsBubbles from '../../components/PhysicsBubbles/PhysicsBubbles';

const tg = window.Telegram.WebApp;

const Home = ({ data }) => {
  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      const overflow = 100;
      document.body.style.overflowY = 'hidden';
      document.body.style.marginTop = `${overflow}px`;
      document.body.style.height = window.innerHeight + overflow + "px";
      document.body.style.paddingBottom = `${overflow}px`;
      window.scrollTo(0, overflow);

      const onTouchMove = (e) => {
        e.preventDefault();
      };

      document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });

      const scrollToTop = () => {
        document.body.scrollTop = 0;
        setTimeout(() => {
          window.scroll({ top: overflow, behavior: "instant" });
        }, 50);
      };

      scrollToTop();
      window.addEventListener('scroll', () => {
        if (window.scrollY < overflow) {
          scrollToTop();
        }
      });

      // Cleanup on unmount
      return () => {
        document.documentElement.removeEventListener('touchmove', onTouchMove);
        window.removeEventListener('scroll', scrollToTop);
        document.body.style.overflowY = '';
        document.body.style.marginTop = '';
        document.body.style.height = '';
        document.body.style.paddingBottom = '';
      };
    }
  }, []);

  useEffect(() => {
    tg.setHeaderColor("#15151F")
    }, []);

  return (
    <>
      <PhysicsBubbles cryptos={data} />
    </>
  );
};

export default Home;