// src/features/menuSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showMenu: false,
  menuContent: ''
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.showMenu = action.payload.show; // true или false
      state.menuContent = action.payload.content;
    },
    closeMenu: (state) => {
      state.showMenu = false;
    },
  }
});

export const { setMenu, closeMenu } = menuSlice.actions;

export default menuSlice.reducer;