import React, { useEffect } from 'react';
import PhysicsBubblesW from '../../components/PhysicsBubbles/PhysicsBubblesW';
import { useSelector } from 'react-redux';
import { selectTimePeriod } from '../../features/timePeriodSlice';
import { selectWatchlistIndex } from '../../features/watchlistSlice';

const tg = window.Telegram.WebApp;

const Watchlist = () => {
  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      const overflow = 100;
      document.body.style.overflowY = 'hidden';
      document.body.style.marginTop = `${overflow}px`;
      document.body.style.height = window.innerHeight + overflow + "px";
      document.body.style.paddingBottom = `${overflow}px`;
      window.scrollTo(0, overflow);

      const onTouchMove = (e) => {
        e.preventDefault();
      };

      document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });

      const scrollToTop = () => {
        document.body.scrollTop = 0;
        setTimeout(() => {
          window.scroll({ top: overflow, behavior: "instant" });
        }, 50);
      };

      scrollToTop();
      window.addEventListener('scroll', () => {
        if (window.scrollY < overflow) {
          scrollToTop();
        }
      });

      // Cleanup on unmount
      return () => {
        document.documentElement.removeEventListener('touchmove', onTouchMove);
        window.removeEventListener('scroll', scrollToTop);
        document.body.style.overflowY = '';
        document.body.style.marginTop = '';
        document.body.style.height = '';
        document.body.style.paddingBottom = '';
      };
    }
  }, []);

  useEffect(() => {
    tg.setHeaderColor("#15151F")
  }, []);

  const { watchlists } = useSelector((state) => state.data);
  const watchlistIndex = useSelector(selectWatchlistIndex);

  const timePeriods = {
    '1h': 'hour',
    '24h': 'day',
    '7d': 'week',
    '30d': 'month',
    '1q': 'quarter',
    '1y': 'year'
  };

  const { coins } = useSelector((state) => state.data);
  const timePeriod = useSelector(selectTimePeriod);
  const coinData = coins && coins.length > 0
    ? coins.map(coin => ({
        id: coin._id,
        name: coin.name,
        symbol: coin.code,
        price: coin.rate,
        growth: coin.percentageDelta[timePeriods[timePeriod]],
        link: coin.png64
    }))
    : [];

  // Получаем первый watchlist
  const firstWatchlist = watchlists.length > 0 ? watchlists[watchlistIndex] : { symbols: [] };
  // Фильтруем монеты по символам в первом watchlist
  const filteredCoinData = coinData.filter(coin => firstWatchlist.symbols.includes(coin.symbol));

  return (
    <>
      <PhysicsBubblesW cryptos={filteredCoinData} />
    </>
  );
};

export default Watchlist;