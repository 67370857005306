import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CryptoPopup from '../../Crypto/CryptoPopup';
import { setMenu, closeMenu } from '../../../features/menuSlice'
import "./MenuChart.css";
import { useSelector, useDispatch } from 'react-redux';

const intervals = {
  '24h': { start: Date.now() - 86400000, end: Date.now() }, // 24 часа
  '7d': { start: Date.now() - 7 * 86400000, end: Date.now() }, // 7 дней
  '1m': { start: Date.now() - 30 * 86400000, end: Date.now() }, // 1 месяц
  '3m': { start: Date.now() - 3 * 30 * 86400000, end: Date.now() }, // 1 квартал
  '1y': { start: Date.now() - 365 * 86400000, end: Date.now() }, // 1 год
  'all': { start: 0, end: Date.now() } // все время
};

function formatPrice(price) {
  // Преобразование входящего значения в число
  const num = parseFloat(price);

  // Проверка на валидное число
  if (isNaN(num)) {
    return 'Invalid number';
  }

  // Форматирование больших чисел с двумя знаками после запятой
  if (num >= 1) {
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  // Форматирование чисел меньше 1
  if (num < 1 && num > 0) {
    const decimalPlaces = Math.abs(Math.floor(Math.log10(num))) + 4; // Определение количества знаков после запятой
    return num.toFixed(decimalPlaces);
  }

  // Возвращаем число как есть для 0
  return num.toString();
}

const CryptoChartPage = () => {
  const dispatch = useDispatch();
  const handleToggleMenu = (content, show) => {
    dispatch(closeMenu())
    setTimeout(() => {
      dispatch(setMenu({ content, show }));
    }, 300);
  };

  const [series, setSeries] = useState([]);
  const [currentRange, setCurrentRange] = useState('1m');
  const [lastTooltipPosition, setLastTooltipPosition] = useState({ seriesIndex: null, dataPointIndex: null });

  const [options, setOptions] = useState({
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      background: '#0F0F16',
      offsetX: 0,
      offsetY: 0,
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      colors: ['#539DF3']
    },
    title: {
      text: '',
      align: 'left'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM yyyy",
          day: 'dd MMM',
          hour: 'HH:mm'
        },
        style: {
          colors: 'rgba(255, 255, 255, 0.6)' 
        }
      }
    },
    yaxis: {
      opposite: true,
      min: 0,
      labels: {
        formatter: function (value) {
          return formatPrice(value);
        },
        style: {
          colors: 'rgba(255, 255, 255, 0.6)' 
        },
        offsetY: 0,
        offsetX: 0, // Отодвигаем цифры ближе к графику
      },
      tickAmount: 4,
      floating: true, // Это свойство позволяет цифрам "плавать" внутри графика
    },
    tooltip: {
      theme: 'dark',
      x: {
        format: 'dd MMM yyyy HH:mm'
      },
      custom: function({ seriesIndex, dataPointIndex, w }) {
        const currentTooltipPosition = { seriesIndex, dataPointIndex };
        
        if (
          lastTooltipPosition.seriesIndex !== currentTooltipPosition.seriesIndex ||
          lastTooltipPosition.dataPointIndex !== currentTooltipPosition.dataPointIndex
        ) {
          window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
          setLastTooltipPosition(currentTooltipPosition);
        }

        const formattedValue = formatPrice(w.globals.series[seriesIndex][dataPointIndex]);

        return `<div>${formattedValue}</div>`;
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        colorStops: [
          {
            offset: 0,
            color: '#539DF3',
            opacity: 0.6
          },
          {
            offset: 100,
            color: '#539DF3',
            opacity: 0
          }
        ]
      }
    },
    grid: {
      show: true,
      borderColor: 'rgba(255, 255, 255, 0.1)', 
      padding: {
        left: 15,  // Отменяем отступ слева
        right: 15, // Отменяем отступ справа
        top: 10,
        bottom: 10
      }
    }
  });
  const cryptoData = useSelector(state => state.cryptoData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { start, end } = intervals[currentRange];
        const response = await axios.get('https://http-api.livecoinwatch.com/coins/history/range', {
          params: {
            coin: cryptoData.symbol,
            start,
            end,
            currency: 'USD'
          }
        });

        // Обработка данных для графика
        const chartData = response.data.data.map(point => ({
          x: new Date(point.date),
          y: point.rate
        }));

        setSeries([{ name: cryptoData.name, data: chartData }]);
      } catch (error) {
        console.error("Ошибка при запросе данных", error);
      }
    };
    fetchData();
  }, [cryptoData, currentRange]);

  const handleRangeChange = (range) => {
    setCurrentRange(range);
    let format = 'dd MMM yyyy HH:mm';
    if (range === '24h') {
      format = 'HH:mm';
    } else if (range === '7d' || range === '1m') {
      format = 'dd MMM';
    } else if (range === '3m' || range === '1y') {
      format = 'MMM yyyy';
    } else {
      format = 'yyyy';
    }
    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        labels: {
          ...prevOptions.xaxis.labels,
          datetimeFormatter: {
            year: 'yyyy',
            month: format,
            day: format,
            hour: format
          }
        }
      }
    }));
  };

  useEffect(() => {
    const chartElement = document.querySelector('.mixed-chart');
    const handleTouchMove = (event) => {
      event.preventDefault();
    };

    chartElement.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      chartElement.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  return (
    <>
      <div className='crp_w'>
        <CryptoPopup/>
        <div className='crp_i' onClick={() => handleToggleMenu('menuFollow', true)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.63937 1.52569C10.605 -0.508562 13.395 -0.508566 14.3606 1.52569L16.0295 5.04141C16.4129 5.84922 17.1541 6.40912 18.0116 6.53866L21.7432 7.10243C23.9024 7.42864 24.7646 10.1875 23.2022 11.7709L20.5019 14.5075C19.8815 15.1363 19.5983 16.0422 19.7448 16.9301L20.3823 20.7943C20.7511 23.0301 18.4939 24.7352 16.5627 23.6795L13.225 21.8551C12.4581 21.4359 11.5419 21.4359 10.775 21.8551L7.43732 23.6795C5.50607 24.7352 3.24891 23.0301 3.61774 20.7943L4.25519 16.9301C4.40165 16.0422 4.11853 15.1363 3.4981 14.5075L0.79785 11.7709C-0.764561 10.1875 0.0975938 7.42864 2.25679 7.10243L5.98845 6.53866C6.84587 6.40912 7.58708 5.84922 7.97053 5.04141L9.63937 1.52569Z" fill="#539DF3"/>
          </svg>
        </div>
        <div className='crp_i' onClick={() => handleToggleMenu('menuBuy', true)}>
          <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.5 0C8.13152 0 6.21149 1.9419 6.21149 4.33735V5.49467C4.04446 5.53241 2.19591 7.10612 1.79514 9.26787L0.0797406 18.5209C-0.448101 21.3681 1.71159 24 4.5758 24H16.4242C19.2884 24 21.4481 21.3681 20.9203 18.5209L19.2049 9.26787C18.8041 7.10612 16.9555 5.53241 14.7885 5.49467V4.33735C14.7885 1.9419 12.8685 0 10.5 0ZM13.0731 5.49398V4.33735C13.0731 2.90008 11.9211 1.73494 10.5 1.73494C9.07891 1.73494 7.92689 2.90008 7.92689 4.33735V5.49398H13.0731Z" fill="#539DF3"/>
          </svg>
        </div>
      </div>
      <div className='chart-container'>
        <div className='mixed-chart'>
          <ReactApexChart options={options} series={series} type="area" height={300} />
        </div>
      </div>
      <div className='poptime'>
          <p className={`timeitem ${currentRange === "24h" ? 'timeitem-active' : 'timeitem-inactive'}`}  onClick={() => {handleRangeChange('24h'); window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid')}}>1D</p>
          <p className={`timeitem ${currentRange === "7d" ? 'timeitem-active' : 'timeitem-inactive'}`} onClick={() => {handleRangeChange('7d'); window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid')}}>7D</p>
          <p className={`timeitem ${currentRange === "1m" ? 'timeitem-active' : 'timeitem-inactive'}`} onClick={() => {handleRangeChange('1m'); window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid')}}>1M</p>
          <p className={`timeitem ${currentRange === "3m" ? 'timeitem-active' : 'timeitem-inactive'}`} onClick={() => {handleRangeChange('3m'); window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid')}}>1Q</p>
          <p className={`timeitem ${currentRange === "1y" ? 'timeitem-active' : 'timeitem-inactive'}`} onClick={() => {handleRangeChange('1y'); window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid')}}>1Y</p>
          <p className={`timeitem ${currentRange === "all" ? 'timeitem-active' : 'timeitem-inactive'}`} onClick={() => {handleRangeChange('all'); window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid')}}>All</p>
      </div>
    </>
  );
}

export default CryptoChartPage;