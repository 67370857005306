import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  timePeriod: '24h',
  sorting: 'top50',
  number: '1'
};

const timePeriodSlice = createSlice({
  name: 'timePeriod',
  initialState,
  reducers: {
    setTimePeriod: (state, action) => {
      state.timePeriod = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setNumber: (state, action) => {  // Новый редуктор для изменения number
      state.number = action.payload;
    }
  }
});

export const { setTimePeriod, setSorting, setNumber } = timePeriodSlice.actions;  // Экспортируем новый редуктор
export const selectTimePeriod = (state) => state.timePeriod.timePeriod;
export const selectSorting = (state) => state.timePeriod.sorting;
export const selectNumber = (state) => state.timePeriod.number;  // Экспортируем селектор для number

export default timePeriodSlice.reducer;