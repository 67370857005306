import React, { useState } from 'react';
import './MenuBuy.css';
import Buy from './Buy/Buy';
import Sell from './Sell/Sell';

const MenuBuy = () => {
  const [action, setAction] = useState('buy'); // по умолчанию 'buy'

  const handleBuyClick = () => {
    setAction('buy');
  };

  const handleSellClick = () => {
    setAction('sell');
  };

  return (
    <div className='container'>
      <div className='wrapperbtn'>
        <div 
          className={`buybtn right ${action === 'buy' ? 'active' : ''}`} 
          onClick={handleBuyClick}
        >
          <svg className='buysvg' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.3998 2.45004L16.3122 5L7.68731 5L9.59977 2.45004C9.8483 2.11867 9.78115 1.64857 9.44977 1.40004C9.1184 1.15152 8.6483 1.21867 8.39977 1.55004L5.80995 5.00314C3.98039 5.07757 2.51371 6.46706 2.11022 8.25H21.8893C21.4858 6.46708 20.0191 5.07759 18.1896 5.00314L15.5998 1.55004C15.3512 1.21867 14.8811 1.15152 14.5498 1.40004C14.2184 1.64857 14.1512 2.11867 14.3998 2.45004ZM21.9712 9.75H2.02832C2.04168 9.86901 2.05982 9.98858 2.08288 10.1085L3.7178 18.6085C4.09763 20.5832 5.71918 22 7.59953 22H16.4C18.2803 22 19.9019 20.5832 20.2817 18.6085L21.9166 10.1085C21.9397 9.98858 21.9578 9.86901 21.9712 9.75ZM7.24976 14C7.24976 13.5858 7.58554 13.25 7.99976 13.25H15.9998C16.414 13.25 16.7498 13.5858 16.7498 14C16.7498 14.4142 16.414 14.75 15.9998 14.75H7.99976C7.58554 14.75 7.24976 14.4142 7.24976 14ZM8.99976 17.25C8.58554 17.25 8.24976 17.5858 8.24976 18C8.24976 18.4142 8.58554 18.75 8.99976 18.75H14.9998C15.414 18.75 15.7498 18.4142 15.7498 18C15.7498 17.5858 15.414 17.25 14.9998 17.25H8.99976Z" fill="#539DF3"/>
          </svg>
          <p>Buy</p>
        </div>
        <div 
          className={`buybtn left ${action === 'sell' ? 'active' : ''}`} 
          onClick={handleSellClick}
        >
          <svg className='buysvg' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.3998 2.45004L16.3122 5L7.68731 5L9.59977 2.45004C9.8483 2.11867 9.78115 1.64857 9.44977 1.40004C9.1184 1.15152 8.6483 1.21867 8.39977 1.55004L5.80995 5.00314C3.98039 5.07757 2.51371 6.46706 2.11022 8.25H21.8893C21.4858 6.46708 20.0191 5.07759 18.1896 5.00314L15.5998 1.55004C15.3512 1.21867 14.8811 1.15152 14.5498 1.40004C14.2184 1.64857 14.1512 2.11867 14.3998 2.45004ZM21.9712 9.75H2.02832C2.04168 9.86901 2.05982 9.98858 2.08288 10.1085L3.7178 18.6085C4.09763 20.5832 5.71918 22 7.59953 22H16.4C18.2803 22 19.9019 20.5832 20.2817 18.6085L21.9166 10.1085C21.9397 9.98858 21.9578 9.86901 21.9712 9.75ZM7.24976 14C7.24976 13.5858 7.58554 13.25 7.99976 13.25H15.9998C16.414 13.25 16.7498 13.5858 16.7498 14C16.7498 14.4142 16.414 14.75 15.9998 14.75H7.99976C7.58554 14.75 7.24976 14.4142 7.24976 14ZM8.99976 17.25C8.58554 17.25 8.24976 17.5858 8.24976 18C8.24976 18.4142 8.58554 18.75 8.99976 18.75H14.9998C15.414 18.75 15.7498 18.4142 15.7498 18C15.7498 17.5858 15.414 17.25 14.9998 17.25H8.99976Z" fill="#EB4335"/>
          </svg>
          <p>Sell</p>
        </div>
      </div>
      {action === 'buy' ? <Buy/> : <Sell/>}
    </div>
  );
}

export default MenuBuy;