import { createSelector } from 'reselect';

// Селектор для получения портфолио
const selectPortfolios = (state) => state.data.portfolios;

// Селектор для получения данных криптовалют
const selectCryptoData = (state) => state.cryptoData;

// Меморизованный селектор для портфолио с трансформацией данных
export const makeSelectPortfolios = createSelector(
    [selectPortfolios],
    (portfolios) => portfolios.map((portfolio) => ({
        ...portfolio,
        name: portfolio.name, // Пример трансформации: все названия портфолио в верхний регистр
    }))
);

// Меморизованный селектор для данных криптовалют с трансформацией данных
export const makeSelectCryptoData = createSelector(
    [selectCryptoData],
    (cryptoData) => ({
        ...cryptoData,
        priceFormatted: formatPrice(cryptoData.price), // Пример трансформации: добавление отформатированной цены
    })
);

function formatPrice(price) {
    const num = parseFloat(price);

    if (isNaN(num)) {
        return 'Invalid number';
    }

    if (num >= 1) {
        return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    const priceString = num.toString();

    if (num < 1 && num >= 0.0001) {
        const decimalPart = priceString.split('.')[1] || '';
        const nonZeroIndex = decimalPart.search(/[^0]/);
        const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
        return `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
    }

    if (num < 0.0001) {
        const decimalPart = priceString.split('.')[1] || '';
        const nonZeroIndex = decimalPart.search(/[^0]/);
        const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
        return `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
    }

    return num.toString();
}