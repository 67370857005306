import { useSelector, useDispatch } from 'react-redux';
import { createPortfolio, renamePortfolio , deletePortfolio } from '../../../features/coinsSlice'
import { selectPortfolioIndex, setPortfolioIndex } from '../../../features/portfolioSlise';
import "../MenuManageWatchlists/MenuManageWatchlists.css";
import telegramId from '../../../api/userId'

const cryptoEmojis = [
  '💰', '💸', '📈', '📉', '💹', '💲', '💳', '🪙', '📊', '🔐', 
  '🔒', '🛡️', '🛠️', '⚒️', '🔗', '🔎', '🔍', '🧠', '👨‍💻', 
  '👩‍💻', '🧑‍💻', '🏦', '🏧', '💱', '💵', '💶', '💷', '💴', 
  '📥', '📤', '🔄', '♻️', '🔔', '🔕', '🌐', '💻', '📱', 
  '⏳', '⌛', '🔧', '💡', '🚀', '🌟', '⭐', '🌍', '🌎', 
  '🌏', '⚙️', '🛒', '🏷️', '💬', '📝', '✉️', '📮', 
  '📨', '📧', '📪', '📫', '📬', '📭', '📦', '📡', 
  '💾', '📅', '📆', '🗓️', '🧭', '🧮', '🪛', '🧬', 
  '🔬', '🔭', '⚖️', '🔋', '🔌', '💻', '📟', '📠', 
  '🖥️', '🖨️', '🕹️', '🖱️', '🖲️', '💽', '💿', 
  '📀', '📺', '📻', '📡', '📞', '☎️', '📠', '📟', 
  '💼', '📂', '📁', '📋', '📜', '📒', '📔', '📕', 
  '📗', '📘', '📙', '📚', '📓', '📖', '📃', '📄', 
  '📑', '🔖', '✏️', '✒️', '🖋️', '🖊️', '🖌️', '🖍️', 
  '🗂️', '🗃️', '🗄️', '🗑️'
];

const ManageW = () => {
  const dispatch = useDispatch();
  const { portfolios } = useSelector((state) => state.data);
  const portfolioIndex = useSelector(selectPortfolioIndex);


  const handleNewWatchlistClick = async () => {
    if (portfolios.length < 5) {
      const randomEmoji = cryptoEmojis[Math.floor(Math.random() * cryptoEmojis.length)];
      const newName = `${randomEmoji} Portfolio`
      dispatch(createPortfolio({ telegramId, "name" : newName }));
    }
  };

  const handleDeleteWatchlistClick = async (portfolioId, event) => {
    event.stopPropagation(); // Останавливает всплытие события, чтобы избежать вызова handleWatchlistClick
    if (portfolioIndex > 0) {
      dispatch(setPortfolioIndex(portfolioIndex -1));
    }
    dispatch(deletePortfolio({ telegramId, portfolioId }));
  };


  const handleNameBlur = async (portfolioId) => {
    const randomEmoji = cryptoEmojis[Math.floor(Math.random() * cryptoEmojis.length)];
    const name = `${randomEmoji} Portfolio`
    dispatch(renamePortfolio({ telegramId, portfolioId, name }));
  };

  return (
    <>
      {portfolios.map((watchlist) => {
        return (
          <div
            key={watchlist._id}
            className='watchlists_con'
          >
            <div className='p_w'>
              <p className='inp_w'>{watchlist.name}</p>
            </div>

            <div className='svg_w rightm3' onClick={() => handleNameBlur(watchlist._id,)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.75 6C2.75 4.20507 4.20507 2.75 6 2.75H12C12.4142 2.75 12.75 2.41421 12.75 2C12.75 1.58579 12.4142 1.25 12 1.25H6C3.37665 1.25 1.25 3.37665 1.25 6V18C1.25 20.6234 3.37665 22.75 6 22.75H18C20.6234 22.75 22.75 20.6234 22.75 18V12C22.75 11.5858 22.4142 11.25 22 11.25C21.5858 11.25 21.25 11.5858 21.25 12V18C21.25 19.7949 19.7949 21.25 18 21.25H6C4.20507 21.25 2.75 19.7949 2.75 18V6ZM16.419 2.67708C17.3218 1.77431 18.7855 1.77431 19.6883 2.67708L21.3229 4.31171C22.2257 5.21449 22.2257 6.67818 21.3229 7.58096L19.8736 9.03028C19.7598 8.97394 19.6401 8.91302 19.516 8.84767C18.6806 8.40786 17.6892 7.79057 16.9493 7.05069C16.2095 6.31082 15.5922 5.31945 15.1524 4.48403C15.087 4.35989 15.0261 4.24018 14.9697 4.12639L16.419 2.67708ZM15.8887 8.11135C16.7642 8.98687 17.8777 9.67594 18.7595 10.1444L13.06 15.8438C12.7064 16.1975 12.2475 16.4269 11.7523 16.4977L8.31963 16.9881C7.5568 17.097 6.90295 16.4432 7.01193 15.6804L7.50231 12.2477C7.57305 11.7525 7.80248 11.2936 8.15616 10.94L13.8556 5.24053C14.3241 6.12234 15.0131 7.23582 15.8887 8.11135Z" fill="#539DF3"/>
              </svg>
            </div>

            <div className='svg_w' onClick={(event) => handleDeleteWatchlistClick(watchlist._id, event)}>
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.35091 2.88778C7.35091 1.2929 8.64382 0 10.2387 0C11.8336 0 13.1265 1.2929 13.1265 2.88778V3.06173C15.6309 3.36652 17.9707 4.06177 20.0346 5.06758C20.4256 5.25813 20.5881 5.72957 20.3976 6.12058C20.207 6.51159 19.7356 6.6741 19.3446 6.48355C16.7281 5.20848 13.6035 4.46294 10.2387 4.46294C6.87388 4.46294 3.74925 5.20848 1.13277 6.48355C0.741763 6.6741 0.270319 6.51159 0.0797715 6.12058C-0.110776 5.72957 0.0517307 5.25813 0.442739 5.06758C2.5067 4.06177 4.84644 3.36652 7.35091 3.06173V2.88778ZM16.8182 17.0989C16.6612 19.297 14.8322 21 12.6285 21H7.84945C5.64575 21 3.81672 19.297 3.65971 17.0989L2.93701 6.98098C5.16041 6.20568 7.63339 5.77351 10.239 5.77351C12.8446 5.77351 15.3175 6.20568 17.541 6.98098L16.8182 17.0989ZM8.92635 9.44887C8.92635 9.01391 8.57374 8.66129 8.13877 8.66129C7.70381 8.66129 7.3512 9.01391 7.3512 9.44887V15.7495C7.3512 16.1845 7.70381 16.5371 8.13877 16.5371C8.57374 16.5371 8.92635 16.1845 8.92635 15.7495V9.44887ZM12.3392 8.66129C12.7742 8.66129 13.1268 9.01391 13.1268 9.44887V15.7495C13.1268 16.1845 12.7742 16.5371 12.3392 16.5371C11.9042 16.5371 11.5516 16.1845 11.5516 15.7495V9.44887C11.5516 9.01391 11.9042 8.66129 12.3392 8.66129Z" fill="#EB4335"/>
              </svg>
            </div>

          </div>
        );
      })}
      <div className='btn_con'>
        <div className='btn_newm3' onClick={handleNewWatchlistClick} style={{ pointerEvents: portfolios.length >= 5 ? 'none' : 'auto', opacity: portfolios.length >= 5 ? 0.5 : 1 }}>
          <p>New</p>
        </div>
      </div>
    </>
  );
}

export default ManageW;