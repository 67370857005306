import { useEffect } from 'react'; 
import Matter from 'matter-js';
import { useDispatch } from 'react-redux';
import { setCryptoData } from '../../features/cryptoDataSlice';
import { setMenu } from '../../features/menuSlice';

const imageCache = {};

const loadImage = (src) => {
    if (imageCache[src]) {
        return Promise.resolve(imageCache[src]);
    } else {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                imageCache[src] = img;
                resolve(img);
            };
            img.onerror = () => {
                // Если произошла ошибка, используем стандартное изображение
                const defaultImg = new Image();
                defaultImg.onload = () => {
                    resolve(defaultImg);
                };
                defaultImg.onerror = reject; // На случай, если даже стандартное изображение не загрузится
                defaultImg.src = '/erricon.png';
            };
            img.src = src;
        });
    }
};

const PhysicsBubblesW = ({ cryptos }) => { 
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
            window.location.reload(); // Перезагрузка страницы при изменении размеров окна
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        
        const handleToggleMenu = (content, show, cryptoData) => {
            dispatch(setMenu({ content, show }));
            if (cryptoData) {
                dispatch(setCryptoData(cryptoData));
            }
        };

        const engine = Matter.Engine.create({
            gravity: { scale: 0 }
        });

        const render = Matter.Render.create({
            element: document.querySelector('#root'),
            engine: engine,
            options: {
                width: window.innerWidth,
                height: window.innerHeight,
                wireframes: false,
                pixelRatio: 2
            }
        });

        const wallThickness = 1000;
        const walls = [
            Matter.Bodies.rectangle(window.innerWidth / 2, -wallThickness / 2, window.innerWidth + wallThickness * 2, wallThickness, { isStatic: true, render: { visible: false }, restitution: 0.1 }),
            Matter.Bodies.rectangle(window.innerWidth / 2, window.innerHeight + wallThickness / 2 - 70, window.innerWidth + wallThickness * 2, wallThickness, { isStatic: true, render: { visible: false }, restitution: 0.1 }),
            Matter.Bodies.rectangle(-wallThickness / 2, window.innerHeight / 2, wallThickness, window.innerHeight + wallThickness * 2, { isStatic: true, render: { visible: false }, restitution: 0.1 }),
            Matter.Bodies.rectangle(window.innerWidth + wallThickness / 2, window.innerHeight / 2, wallThickness, window.innerHeight + wallThickness * 2, { isStatic: true, render: { visible: false }, restitution: 0.1 })
        ];

        const maxGrowth = Math.max(...cryptos.map(crypto => Math.abs(crypto.growth)));

        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const totalScreenArea = screenWidth * screenHeight;
        const targetBubblesArea = totalScreenArea * 0.65;

        const maxBubbleSize = Math.min(screenWidth * 0.40, 500); // Увеличили максимальный размер до 500px
        const minBubbleSize = Math.max(screenWidth * 0.05, 20);  // Увеличили минимальный размер до 20px

        const sumOfNormalizedGrowth = cryptos.reduce((sum, crypto) => {
            const normalizedGrowth = Math.pow(Math.abs(crypto.growth) / maxGrowth, 2); // Усиливаем нормализацию
            return sum + normalizedGrowth;
        }, 0);

        const initialVelocity = {
            x: 10, // Случайная начальная скорость по x
            y: 10  // Случайная начальная скорость по y
        };

        const bubbles = cryptos.map(crypto => {
            const normalizedGrowth = Math.pow(Math.abs(crypto.growth) / maxGrowth, 2); // Усиливаем нормализацию
            const area = (normalizedGrowth / sumOfNormalizedGrowth) * targetBubblesArea;
            const radius = Math.sqrt(area / Math.PI);
            const clampedRadius = Math.max(minBubbleSize / 2, Math.min(maxBubbleSize / 2, radius));

            const circle = Matter.Bodies.circle(
                Math.random() * screenWidth, Math.random() * screenHeight, clampedRadius, {
                    restitution: 0.9,
                    frictionAir: 0.05,
                    label: crypto?.symbol?.replace(/_/g, "").slice(0, 8),
                    growth: crypto.growth,
                    render: { fillStyle: 'transparent' }
                }
            );

            // Устанавливаем начальную скорость
            Matter.Body.setVelocity(circle, initialVelocity);

            // Загрузка изображений с кэшированием
            loadImage(crypto.link).then(img => {
                circle.image = img;
            }).catch(error => {
                
            });

            return circle;
        });

        Matter.Events.on(render, 'afterRender', function() {
            bubbles.forEach(function(bubble) {
                const pos = bubble.position;
                const ctx = render.context;

                const gradient = ctx.createRadialGradient(pos.x, pos.y, 0, pos.x, pos.y, bubble.circleRadius);
                const colorBase = bubble.growth >= 0 ? '23, 240, 84' : '239, 85, 85';
                gradient.addColorStop(0, `rgba(${colorBase}, 0)`);    
                gradient.addColorStop(0.88, `rgba(${colorBase}, 0.3)`);
                gradient.addColorStop(1, `rgba(${colorBase}, 1)`);
                
                ctx.beginPath();
                ctx.fillStyle = gradient;
                ctx.arc(pos.x, pos.y, bubble.circleRadius, 0, Math.PI * 2);
                ctx.fill();
                ctx.closePath();

                const thresholdSize = Math.min(screenWidth * 0.10, 75);
                if (bubble.circleRadius > thresholdSize / 2) {
                    const textSizeMain = bubble.circleRadius / 3.5;
                    const textSizeGrowth = bubble.circleRadius / 5;
                    const textPosY = pos.y + textSizeMain * 0.6;
                    if (bubble.image) {
                        const imageSize = bubble.circleRadius * 0.55;
                        const imgX = pos.x - imageSize / 2;
                        const imgY = pos.y - imageSize - textSizeMain * 0.6;
                        ctx.drawImage(bubble.image, imgX, imgY, imageSize, imageSize);
                    }
                    ctx.fillStyle = 'white';
                    ctx.font = `bold ${textSizeMain}px Arial`;
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillText(bubble.label, pos.x, textPosY);
                    if (typeof bubble.growth === 'number') {
                        ctx.font = `${textSizeGrowth}px Arial`;
                        ctx.fillText(`${bubble.growth.toFixed(2)}%`, pos.x, textPosY + textSizeMain);
                    }
                } else if (bubble.image) {
                    const imageSize = bubble.circleRadius * 0.9;
                    const imgX = pos.x - imageSize / 2;
                    const imgY = pos.y - imageSize / 2;
                    ctx.drawImage(bubble.image, imgX, imgY, imageSize, imageSize);
                }
            });
        });

        Matter.World.add(engine.world, [...bubbles, ...walls]);

        const mouse = Matter.Mouse.create(render.canvas);
        const mouseControl = Matter.MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                render: { visible: false },
                stiffness: 0.2
            }
        });
        Matter.World.add(engine.world, mouseControl);

        Matter.Events.on(mouseControl, 'mousemove', function(event) {
            const { body } = event.source.constraint;
            if (body) {
                const maxSpeed = 10;
                Matter.Body.setVelocity(body, {
                    x: Math.max(Math.min(body.velocity.x, maxSpeed), -maxSpeed),
                    y: Math.max(Math.min(body.velocity.y, maxSpeed), -maxSpeed)
                });
            }
        });

        let initialPoint = null;

        const handlePointerDown = (event) => {
            initialPoint = { x: event.clientX, y: event.clientY };
        };

        const handlePointerUp = (event) => {
            const finalPoint = { x: event.clientX, y: event.clientY };
            if (initialPoint && initialPoint.x === finalPoint.x && initialPoint.y === finalPoint.y) {
                const mousePoint = { x: event.clientX, y: event.clientY };
                const bodies = Matter.Query.point(bubbles, mousePoint);
                if (bodies.length > 0) {
                    const bubbleData = bodies[0];
                    const cryptoData = cryptos.find(crypto => crypto.symbol.replace(/_/g, "") === bubbleData.label);
                    if (cryptoData) {
                        handleToggleMenu('menuChart', true, cryptoData);
                    }
                }
            }
            initialPoint = null;
        };

        render.canvas.addEventListener('pointerdown', handlePointerDown);
        render.canvas.addEventListener('pointerup', handlePointerUp);

        Matter.Runner.run(engine);
        Matter.Render.run(render);

        return () => {
            render.canvas.removeEventListener('pointerdown', handlePointerDown);
            render.canvas.removeEventListener('pointerup', handlePointerUp);
            Matter.Render.stop(render);
            Matter.Runner.stop(engine);
            Matter.World.clear(engine.world);
            Matter.Engine.clear(engine);
            render.canvas.remove();
        };
    }, [cryptos, dispatch]);

    return null;
};

export default PhysicsBubblesW;







