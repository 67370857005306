import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import PhysicsBubblesW from '../../components/PhysicsBubbles/PhysicsBubblesW';
import { selectPortfolioIndex } from '../../features/portfolioSlise';

const tg = window.Telegram.WebApp;

const Portfolio = () => {
  const { coins, portfolios } = useSelector((state) => state.data);
  const portfolioIndex = useSelector(selectPortfolioIndex);

  useEffect(() => {
    tg.setHeaderColor("#15151F")
  }, []);

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      const overflow = 100;
      document.body.style.overflowY = 'hidden';
      document.body.style.marginTop = `${overflow}px`;
      document.body.style.height = window.innerHeight + overflow + "px";
      document.body.style.paddingBottom = `${overflow}px`;
      window.scrollTo(0, overflow);

      const onTouchMove = (e) => {
        e.preventDefault();
      };

      document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });

      const scrollToTop = () => {
        document.body.scrollTop = 0;
        setTimeout(() => {
          window.scroll({ top: overflow, behavior: "instant" });
        }, 50);
      };

      scrollToTop();
      window.addEventListener('scroll', () => {
        if (window.scrollY < overflow) {
          scrollToTop();
        }
      });

      // Cleanup on unmount
      return () => {
        document.documentElement.removeEventListener('touchmove', onTouchMove);
        window.removeEventListener('scroll', scrollToTop);
        document.body.style.overflowY = '';
        document.body.style.marginTop = '';
        document.body.style.height = '';
        document.body.style.paddingBottom = '';
      };
    }
  }, []);

  // Проверяем, существуют ли данные
  if (!coins || !portfolios || portfolios.length === 0) {
    return null;
  }

  const firstPortfolio = portfolios[portfolioIndex];
  const portfolioSymbols = firstPortfolio.coins.map(coin => coin.symbol);

  const coinData = coins.length > 0
    ? coins
        .filter(coin => portfolioSymbols.includes(coin.code))
        .map(coin => {
          const portfolioCoin = firstPortfolio.coins.find(pCoin => pCoin.symbol === coin.code);
          if (!portfolioCoin) {
            console.error(`Portfolio coin not found for symbol: ${coin.code}`);
            return null;
          }
          const growth = ((coin.rate - portfolioCoin.purchasePrice) / portfolioCoin.purchasePrice) * 100;
          const formattedGrowth = parseFloat(growth.toFixed(2));
          return {
            id: portfolioCoin._id,
            name: coin.name,
            symbol: portfolioCoin.symbol,
            price: coin.rate,
            growth: formattedGrowth !== 0 ? formattedGrowth : Number.EPSILON,
            link: coin.png64
          };
        })
        .filter(coin => coin !== null) // Удаляем null значения
    : [];

  return (
    <>
      <PhysicsBubblesW cryptos={coinData} />
    </>
  );
};

export default Portfolio;