import React from 'react';
import './MenuPages.css'

const Menu2 = ({ handleMenuClickRange }) => {

  return (
    <div>

      <div className='inpselection'>
        <div className='inpitem' style={{marginRight: '15px'}} onClick={() => handleMenuClickRange('1')}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}}>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.3194 11.9358C22.2155 11.5097 24.4386 9.01447 24.4386 5.99999C24.4386 2.68629 21.7523 0 18.4386 0C15.4241 0 12.9289 2.22306 12.5028 5.11919C12.4605 5.40666 12.4386 5.70077 12.4386 5.99999C12.4386 9.31369 15.1249 12 18.4386 12C18.7378 12 19.0319 11.9781 19.3194 11.9358ZM10.0394 4.80001C10.2936 4.80001 10.5455 4.80989 10.7947 4.82929C10.7888 4.86558 10.7832 4.90196 10.7779 4.93843C10.7229 5.31214 10.6944 5.69448 10.6944 6.08346C10.6944 10.3913 14.1866 13.8834 18.4944 13.8834C18.877 13.8834 19.2532 13.8559 19.6211 13.8027C19.6333 14.0002 19.6394 14.1994 19.6394 14.4C19.6394 19.7019 15.3414 24 10.0394 24C4.73751 24 0.439453 19.7019 0.439453 14.4C0.439453 9.09807 4.73751 4.80001 10.0394 4.80001Z" fill="white" opacity="0.6"/>
          </svg>
          <p>0</p>
        </div>
        <div className='inpitem' onClick={() => handleMenuClickRange('2')}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}}>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.3194 11.9358C22.2155 11.5097 24.4386 9.01447 24.4386 5.99999C24.4386 2.68629 21.7523 0 18.4386 0C15.4241 0 12.9289 2.22306 12.5028 5.11919C12.4605 5.40666 12.4386 5.70077 12.4386 5.99999C12.4386 9.31369 15.1249 12 18.4386 12C18.7378 12 19.0319 11.9781 19.3194 11.9358ZM10.0394 4.80001C10.2936 4.80001 10.5455 4.80989 10.7947 4.82929C10.7888 4.86558 10.7832 4.90196 10.7779 4.93843C10.7229 5.31214 10.6944 5.69448 10.6944 6.08346C10.6944 10.3913 14.1866 13.8834 18.4944 13.8834C18.877 13.8834 19.2532 13.8559 19.6211 13.8027C19.6333 14.0002 19.6394 14.1994 19.6394 14.4C19.6394 19.7019 15.3414 24 10.0394 24C4.73751 24 0.439453 19.7019 0.439453 14.4C0.439453 9.09807 4.73751 4.80001 10.0394 4.80001Z" fill="white" opacity="0.6"/>
          </svg>
          <p>50</p>
        </div>
      </div>

      <div className='infobar'>
        <svg width="24" height="24" style={{marginRight: '10px'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.9 7.2C12.9 6.70294 12.4971 6.3 12 6.3C11.5029 6.3 11.1 6.70294 11.1 7.2V8.4C11.1 8.89706 11.5029 9.3 12 9.3C12.4971 9.3 12.9 8.89706 12.9 8.4V7.2ZM12.9 11.4C12.9 10.9029 12.4971 10.5 12 10.5C11.5029 10.5 11.1 10.9029 11.1 11.4V16.8C11.1 17.2971 11.5029 17.7 12 17.7C12.4971 17.7 12.9 17.2971 12.9 16.8V11.4Z" fill="white" opacity="0.6"/>
        </svg>
        <p style={{fontSize: '14px'}}>Pick from 2000 cryptos - up to 100 balls.</p>
      </div>

    </div>
  );
};

export default Menu2;