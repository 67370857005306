import { configureStore } from '@reduxjs/toolkit';
import coinsReducer from '../features/coinsSlice';
import timePeriodReducer from '../features/timePeriodSlice';
import menuReducer from '../features/menuSlice'
import cryptoDataReducer from '../features/cryptoDataSlice'
import cryptoReducer from '../features/cryptoSlice';
import watchlistReducer from '../features/watchlistSlice'
import portfolioReduser from '../features/portfolioSlise'

export const store = configureStore({
    reducer: {
        data: coinsReducer,
        timePeriod: timePeriodReducer,
        menu: menuReducer,
        cryptoData: cryptoDataReducer,
        crypto: cryptoReducer,
        watchlist: watchlistReducer,
        portfolio: portfolioReduser
    }
});