import './Crypto.css'
import { useDispatch } from 'react-redux';
import { setCryptoData } from '../../features/cryptoDataSlice';
import { setMenu } from '../../features/menuSlice';

const Crypto = ({ link, name, symbol, price, growth, id }) => {
    const dispatch = useDispatch();
    

    const handleToggleMenu = (content, show) => {
        dispatch(setMenu({ content, show }));
        dispatch(setCryptoData({ link, name, symbol, price, growth, id }));
    };

    const formattedGrowth = isNaN(parseFloat(growth)) ? '0.00' : parseFloat(growth).toFixed(2);
    const growthStyle = {
        color: formattedGrowth.startsWith('-') ? '#E83636' : '#68B820'
    };

    function formatPrice(price) {
        // Преобразование строки в число
        const num = parseFloat(price);
      
        // Проверка на валидное число
        if (isNaN(num)) {
          return 'Invalid number';
        }
      
        // Форматирование больших чисел с двумя знаками после запятой
        if (num >= 1) {
          return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
      
        // Форматирование чисел меньше 1 но больше или равных 0.0001
        if (num < 1 && num >= 0.0001) {
          const decimalPart = price.split('.')[1];
          const nonZeroIndex = decimalPart.search(/[^0]/);
          const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
          return `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
        }
      
        // Форматирование очень маленьких чисел с 4 значащими цифрами после последних нулей
        if (num < 0.0001) {
          const decimalPart = price.split('.')[1];
          const nonZeroIndex = decimalPart.search(/[^0]/);
          const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
          return `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
        }
      
        // Возвращаем число как есть для 1
        return num.toString();
      }
      


    return (
        <div className='container_crypto' onClick={() => handleToggleMenu('menuChart', true)}>

            <div className='crypto_info'>
                <img src={link} alt="Bitcoin" style={{ width: "30px", height: "30px", marginRight: "10px" }} onError={(e) => { e.target.src = '/erricon.png'; }} />
                <div>
                    <p>{name}</p>
                    <p style={{fontSize: "14px", opacity: "0.6"}}>{symbol.replace(/_/g, "")}</p>
                </div>
            </div>
            <div className='price_crypto'>
                <div>
                    <p style={{fontSize: '14px'}}>${formatPrice(price)}</p>
                </div>

                <div>
                    <p style={growthStyle}>{formattedGrowth}%</p>
                </div>
            </div>

        </div>
    )
}

export default Crypto