import { createSlice } from '@reduxjs/toolkit';

const cryptoSlice = createSlice({
  name: 'crypto',
  initialState: {
    currentRange: '30d',
  },
  reducers: {
    setCurrentRange: (state, action) => {
      state.currentRange = action.payload;
    }
  },
});

export const { setCurrentRange } = cryptoSlice.actions;
export default cryptoSlice.reducer;