import { useSelector, useDispatch } from 'react-redux';
import { addSymbol, removeSymbol, createWatchlist } from '../../../features/coinsSlice';
import { setMenu, closeMenu } from '../../../features/menuSlice'
import userId from '../../../api/userId'
import "./MenuFollow.css";

const Menu3 = () => {
  const dispatch = useDispatch();
  const handleToggleMenu = (content, show) => {
    dispatch(closeMenu())
    setTimeout(() => {
      dispatch(setMenu({ content, show }));
    }, 300);
  };

  const { watchlists } = useSelector((state) => state.data);
  const cryptoData = useSelector(state => state.cryptoData);

  const handleWatchlistClick = async (watchlist) => {
    const containsSymbol = watchlist.symbols.includes(cryptoData.symbol);

    if (containsSymbol) {
      dispatch(removeSymbol({ userId, watchlistId: watchlist._id, symbol: cryptoData.symbol }));
    } else {
      dispatch(addSymbol({ userId, watchlistId: watchlist._id, symbol: cryptoData.symbol }));
    }
  };

  const handleNewWatchlistClick = async () => {
    if (watchlists.length < 5) {
      dispatch(createWatchlist({ userId }));
    }
  };

  return (
    <>
      {watchlists.map((watchlist, index) => {
        const containsSymbol = watchlist.symbols.includes(cryptoData.symbol);

        return (
          <div key={watchlist._id} className='watchlists_conm3'>
            <div className='p_wm3'>
            <p>{watchlist.name}</p>
            </div>
            <div className='btnwtch' onClick={() => handleWatchlistClick(watchlist)}>
              {containsSymbol ? (
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM16.0305 16.0304C15.6791 16.3819 15.1092 16.3819 14.7577 16.0304L12 13.2727L9.24228 16.0305C8.89081 16.3819 8.32096 16.3819 7.96949 16.0305C7.61802 15.679 7.61802 15.1091 7.96949 14.7577L10.7272 11.9999L7.96951 9.24221C7.61803 8.89074 7.61803 8.32089 7.96951 7.96942C8.32098 7.61795 8.89083 7.61795 9.2423 7.96942L12 10.7271L14.7577 7.96943C15.1092 7.61796 15.679 7.61796 16.0305 7.96943C16.382 8.3209 16.382 8.89075 16.0305 9.24222L13.2728 11.9999L16.0305 14.7576C16.382 15.1091 16.382 15.679 16.0305 16.0304Z" fill="#EB4335"/>
                  </svg>
                ) : (
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 6.3C12.4971 6.3 12.9 6.70294 12.9 7.2V11.1H16.8C17.2971 11.1 17.7 11.5029 17.7 12C17.7 12.4971 17.2971 12.9 16.8 12.9H12.9V16.8C12.9 17.2971 12.4971 17.7 12 17.7C11.5029 17.7 11.1 17.2971 11.1 16.8V12.9H7.2C6.70294 12.9 6.3 12.4971 6.3 12C6.3 11.5029 6.70294 11.1 7.2 11.1H11.1V7.2C11.1 6.70294 11.5029 6.3 12 6.3Z" fill="#539DF3"/>
                  </svg>
              )}
            </div>
          </div>
        );
      })}
      <div className='btn_conm3'>
        <div className='btn_newm3 rightm3' onClick={handleNewWatchlistClick} style={{ pointerEvents: watchlists.length >= 5 ? 'none' : 'auto', opacity: watchlists.length >= 5 ? 0.5 : 1 }}>
          <p>New</p>
        </div>
        <div className='btn_newm3' onClick={() => handleToggleMenu('menuManageWatchlists', true)}>
          <p>Manage</p>
        </div>
      </div>
    </>
  );
}

export default Menu3;