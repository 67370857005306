
const Crypto = ({ link, name, symbol, price, amount}) => {


    function formatPrice(price, isEmptyPortfolio = false) {
        if (isNaN(price) || isEmptyPortfolio) {
            return '0.00';
        }

        const isNegative = price < 0;
        const absPrice = Math.abs(price);

        if (absPrice >= 1) {
            const formattedPrice = absPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            return isNegative ? `-${formattedPrice}` : formattedPrice;
        }

        if (absPrice < 1 && absPrice >= 0.0001) {
            const decimalPart = absPrice.toFixed(8).split('.')[1];  // Округляем до 8 знаков после запятой
            const nonZeroIndex = decimalPart.search(/[^0]/);
            if (nonZeroIndex === -1) {
                return '0.00';
            }
            const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
            const formattedPrice = `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
            return isNegative ? `-${formattedPrice}` : formattedPrice;
        }

        if (absPrice < 0.0001) {
            const decimalPart = absPrice.toFixed(12).split('.')[1];  // Округляем до 12 знаков после запятой для маленьких чисел
            const nonZeroIndex = decimalPart.search(/[^0]/);
            if (nonZeroIndex === -1) {
                return '0.00';
            }
            const significantPart = decimalPart.substring(nonZeroIndex, nonZeroIndex + 4);
            const formattedPrice = `0.${'0'.repeat(nonZeroIndex)}${significantPart}`;
            return isNegative ? `-${formattedPrice}` : formattedPrice;
        }

        return price.toString();
    }


    return (
        <div className='container_crypto'>

            <div className='crypto_info'>
                <img src={link} alt="Bitcoin" style={{ width: "35px", height: "35px", marginRight: "10px" }} onError={(e) => { e.target.src = '/erricon.png'; }} />
                <div>
                    <p style={{ fontWeight: "bold" }}>{name}</p>
                    <p style={{fontSize: "14px", opacity: "0.6"}}>{symbol.replace(/_/g, "")}</p>
                </div>
            </div>
            <div className='price_crypto'>
                <div>
                    <p>{amount} {symbol.replace(/_/g, "")}</p>
                </div>

                <div>
                    <p style={{color: "rgb(255,255,255,0.6)"}}>${formatPrice(price)}</p>
                </div>
            </div>

        </div>
    )
}

export default Crypto