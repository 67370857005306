import { useSelector, useDispatch } from 'react-redux';
import { createWatchlist } from '../../../features/coinsSlice';
import { setMenu, closeMenu } from '../../../features/menuSlice'
import { selectWatchlistIndex, setWatchlistIndex } from '../../../features/watchlistSlice';
import "./MenuWatchlists.css";
import userId from '../../../api/userId';

const Menu4 = () => {
    const { watchlists } = useSelector((state) => state.data);
    const watchlistIndex = useSelector(selectWatchlistIndex);

    const dispatch = useDispatch();
    const handleToggleMenu = (content, show) => {
        dispatch(closeMenu())
        setTimeout(() => {
        dispatch(setMenu({ content, show }));
        }, 300);
    };

    const handleNewWatchlistClick = async () => {
        if (watchlists.length < 5) {
          dispatch(createWatchlist({ userId }));
        }
      };

    const handleWatchlistClick = (index) => {
        dispatch(setWatchlistIndex(index));
        dispatch(closeMenu())
    };

    
    return (
        <>
        {watchlists.map((watchlist, index) => {
            return (
                <div 
                key={watchlist._id} 
                className='watchlists_con2'
                onClick={() => handleWatchlistClick(index)}
              >
                <div className={`p_w2 ${index === watchlistIndex ? 'active2' : ''}`}>
                    <p>{watchlist.name}</p>
                </div>
            </div>
            );
        })}
        <div className='btn_conm3'>
        <div className='btn_newm3 rightm3' onClick={handleNewWatchlistClick} style={{ pointerEvents: watchlists.length >= 5 ? 'none' : 'auto', opacity: watchlists.length >= 5 ? 0.5 : 1 }}>
          <p>New</p>
        </div>
        <div className='btn_newm3' onClick={() => handleToggleMenu('menuManageWatchlists', true)}>
          <p>Manage</p>
        </div>
      </div>
        </>
    )
    }

export default Menu4