import React from 'react';
import { useSelector } from 'react-redux';
import { selectTimePeriod } from '../../../features/timePeriodSlice';

const Menu1 = ({ handleMenuClick }) => {
  const selectedPeriod = useSelector(selectTimePeriod);

  // Функция для преобразования кода периода в читаемый текст
  const formatPeriod = (period) => {
    switch (period) {
      case '1h': return '1H';
      case '24h': return '1D';
      case '7d': return '7D';
      case '30d': return '1M';
      case '1q': return '1Q';
      case '1y': return '1Y';
      default: return period;
    }
  };

  return (
    <div className='menu_time'>
      {['1h', '24h', '7d', '30d', "1q", '1y'].map(period => (
        <div key={period} className={`menu_time_item ${selectedPeriod === period ? 'menu_color' : ''}`} onClick={() => {
          handleMenuClick(period);
          window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid')
          }}>
          <p>{formatPeriod(period)}</p>
        </div>
      ))}
    </div>
  );
};

export default Menu1;
